:root {
  --primary-color: #e0e3e5;
  --secondary-color: #00406c;
  --text-color: #e0e3e5;
  --app-background-color: #201f24;
  --page-background-color: #000000;
  --app-chrome-color: #1a1a1a;
  --border-radius-small: 5px;
  --header-height: 60px;
  --tabbar-height: 90px;
  --show-card-height: 130px;
}

/* Apply the dark background to the entire screen, make body and html take full width and height */
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--page-background-color); /* Set the dark background color */
  color: var(--text-color);
}

/* Center the content only within the #app-container */
#app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--text-color);
  font-family: "Inter", sans-serif;
  gap: 10px;

  /* Set width and height of the app container relative to its content, not the entire screen */
  width: auto;
  height: auto;
}
/* html, body, #app-container {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--page-background-color);
    font-family: 'Inter', sans-serif; 
    gap: 10px;
} */

#app-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

#refresh-indicator {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
  transition: transform 0.4s;
  display: none;
}
#refresh-indicator.show {
  display: block;
  transform: translateY(0%) translateX(-50%);
}

.app-chrome-element {
  display: flex;
  position: absolute;
  flex-shrink: 0;
  left: 0;
  right: 0;
  z-index: 10;
  align-items: center;
  background-color: var(--app-chrome-color);
  backdrop-filter: blur(5px);
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

.phone {
  --phone-width: 414px;

  /* background-color: var(--app-background-color); */

  width: var(--phone-width);
  height: 896px;
  border-radius: 30px;
  overflow: hidden;
  border: 3px solid #333;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 20px 44px rgba(50, 50, 93, 0.12), 0 -1px 32px rgba(50, 50, 93, 0.06), 0 3px 12px rgba(0, 0, 0, 0.08), inset 0 -2px 5px rgba(10, 37, 64, 0.35);
}

#tab-view {
  position: absolute;
  width: 100%;

  height: 100%;
  top: 0px;
  bottom: 0px;
}

#tab-view .tab-bar {
  opacity: 1;
  border-top: 3px solid #333;
}

.tab-bar-browser {
  height: 90px;
}

.tab-bar-standalone {
  height: 90px;
}

/** App Screens */
.daily-edge-screen {
  padding-top: var(--header-height);
  padding-bottom: var(--tabbar-height);
  background-color: #000000;
  /* padding-left : 10px; */
}

.coming-soon-screen {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 1em;

  text-align: center;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}

.learn-screen {
  background: linear-gradient(to right, #f0f1bd, #63006a);
}

.monthly-outlook-screen {
  background: linear-gradient(to left, #f64f59, #c471ed, #12c2e9);
}

.trade-ideas-screen {
  background: linear-gradient(to left, #91eae4, #86a8e7, #7f7fd5);
}

/** making sure it works as epxected on mobile or when phone won't fit well in the browser */
@media only screen and (max-width: 786px) {
  .phone {
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }
  .tab-bar-browser {
    height: 68px;
    padding-bottom: 4px;
  }

  .tab-bar-standalone {
    height: 90px;
  }
}

.tab-content-container::-webkit-scrollbar {
  width: 10px;
}

/* WebKit browsers (Chrome, Safari, newer versions of Edge) */
.tab-content-container::-webkit-scrollbar {
  width: 10px;
}

.tab-content-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make it transparent by default */
  border-radius: 5px;
  border: 2px solid transparent;
  background-clip: content-box;
}

.tab-content-container::-webkit-scrollbar-track {
  background-color: transparent; /* Make it transparent by default */
  border-radius: 5px;
}

.tab-content-container:hover::-webkit-scrollbar-thumb {
  background-color: #555; /* Color on hover */
}

.tab-content-container:hover::-webkit-scrollbar-track {
  background-color: #333; /* Color on hover */
}

/* Firefox */
.tab-content-container {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; /* Make it transparent by default */
}

.tab-content-container:hover {
  scrollbar-color: #555 #333; /* Color on hover */
}

#loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s 1s opacity 1s linear 1s;
  z-index: 1000;
}

.overlay-visible {
  visibility: visible;
  opacity: 1;
  /* transition-delay: 1s; */
}

#loading-overlay p {
  color: white;
  font-size: 1.5em;
  font-weight: bold;
}
